<template>
  <v-card flat min-height="500" class="rounded-10"> 
    <router-view :key="$route.fullPath"></router-view>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: true,
  }),
};
</script>
<style lang="scss" scoped>
 
</style>